import { FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Utils } from "../../utils/utils";
import { PRIVATE_ROUTES, TRANSLATION_TAB } from "../../helper/constants";

import PaginationComponent from "../Common/PaginationComponent";
import actions from "../../store/actions/actions";
import { useDispatch } from "react-redux";

export const FloorTranslation = ({
  data,
  languageIds,
  languageNames,
  setHasDataChanged,
  hotelId,
  filter,
  pageNo,
  totalCount,
  onPageChange,
}) => {
  const defaultLimit = 10;
  const [allowAccess, setAllowAccess] = useState(true);
  const [floorsEditorData, setFloorsEditorData] = useState({
    translation: [],
  });

  const [categoryTranslation, setCategoryTranslationData] = useState([]);
  const [forHeader, setForHeader] = useState([
    {
      header: "Name",
    },
    {
      header: "Field",
    },
  ]);
  const { t } = useTranslation();
  const formikRef = useRef();
  const dispatch = useDispatch();

  const formInitialValue = {
    translation: categoryTranslation,
  };

  useEffect(() => {
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.floor_create));
    if (data) {
      setForHeader([...forHeader, ...languageNames]);
      const finalData = Utils.refactorCategoryTranslationData(
        data,
        "h_floor_ts"
      );
      const filteredData = Utils.filterCategoryTranslation(
        finalData,
        [...languageIds],
        "Floor Name",
        "Description",
        TRANSLATION_TAB.FLOOR
      );
      setFloorsEditorData({
        translation: filteredData,
      });
      formikRef.current.setFieldValue("translation", filteredData);
    }
  }, [data]);

  const updateCategoryTranslation = () => {
    const formValue = floorsEditorData?.translation;
    const floorData = formValue;
    floorData.forEach((categoryInfo) => {
      delete categoryInfo.descriptionLabel;
      delete categoryInfo.nameLabel;
      delete categoryInfo.name;
      categoryInfo.h_floor_ts = categoryInfo.fields;
      // delete categoryInfo.fields;
      // categoryInfo.hotelId = hotelId;
    });
    setHasDataChanged(false);

    dispatch(
      actions.updateTranslation(
        { floor: floorData },
        hotelId,
        filter,
        pageNo,
        defaultLimit
      )
    );
  };

  const updatePagination = (pageNo) => {
    onPageChange({ page: +pageNo });
  };

  return (
    <div className="row-vertical-scroll">
      <Row className="mb-3">
        {forHeader?.map((headerInfo) => (
          <>
            <Col>
              <strong>{headerInfo?.header}</strong>
            </Col>
          </>
        ))}
      </Row>
      <Formik
        innerRef={formikRef}
        initialValues={{ ...formInitialValue }}
        onSubmit={(values) => {
          updateCategoryTranslation();
        }}
      >
        {({ handleChange, values, submitForm, setFieldValue }) => (
          <>
            <FieldArray name="translations">
              {() => {
                const translationArray = values?.translation;
                return (
                  <>
                    {translationArray && translationArray.length ? (
                      <>
                        <Row>
                          {translationArray?.map((floorInfo, index) => (
                            <>
                              <Col>{floorInfo?.name}</Col>
                              <Col>
                                <div>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    value={floorInfo.nameLabel}
                                    disabled={true}
                                    className="mt-3"
                                  />
                                </div>
                                {/* <div>
                                  <bootstrapForm.Control
                                    key={`translation-label-${index}`}
                                    type="text"
                                    disabled={true}
                                    value={floorInfo.descriptionLabel}
                                    className="mt-3"
                                  />
                                </div> */}
                              </Col>
                              {floorInfo?.fields.map(
                                (translationValue, tagIndex) => (
                                  <Col>
                                    <div>
                                      <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        disabled={
                                          translationValue?.language_id === 1
                                        }
                                        value={translationValue?.name}
                                        className="mt-3"
                                        name={`translation.${index}.fields.${tagIndex}.name`}
                                        onChange={(event) => {
                                          if (
                                            event.target.value.trim().length > 0
                                          ) {
                                            let originalName =
                                              translationValue.original_name
                                                ? translationValue.original_name
                                                : "";

                                            const isDescriptionUpdated =
                                              originalName.localeCompare(
                                                event.target.value
                                              );
                                            if (isDescriptionUpdated)
                                              setHasDataChanged(true);

                                            floorsEditorData.translation[
                                              index
                                            ].fields[tagIndex].name =
                                              event.target.value;
                                            handleChange(event);
                                          } else if (
                                            event.target.value.trim().length < 1
                                          ) {
                                            floorsEditorData.translation[
                                              index
                                            ].fields[tagIndex].name = "";
                                            setFieldValue(
                                              `translation.${index}.fields.${tagIndex}.name`,
                                              ""
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    {/* <div>
                                      <bootstrapForm.Control
                                        key={`translation-form-${tagIndex}`}
                                        type="text"
                                        value={translationValue?.description}
                                        className="mt-3"
                                        name={`translation.${index}.fields.${tagIndex}.description`}
                                        onChange={handleChange}
                                      />
                                    </div> */}
                                  </Col>
                                )
                              )}
                              <span className="mt-2 mb-2">
                                <hr />
                              </span>
                            </>
                          ))}
                          {/* } */}
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              }}
            </FieldArray>
            <Row>
              <Col>
                <PaginationComponent
                  pageNo={+pageNo}
                  totalCount={Math.ceil(totalCount / 10) || 1}
                  onChange={(number) => {
                    updatePagination(number);
                  }}
                />
              </Col>
            </Row>

            {allowAccess ? (
              <Button
                className="mt-3"
                variant="primary"
                onClick={() => {
                  submitForm();
                }}
              >
                {t("Common Translations.Save")}
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};
