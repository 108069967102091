import React, { useEffect, useState } from "react";
import Select from "react-select";
import Spinner from "./Spinner";

const SingleDropDown = (props) => {
  const sendDropdownValue = (value) => {
    props.updateDropdown(props.inpName, value !== null ? value.value : value);
  };

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  let invalidStyle = {};

  let isInvalid =
    props.touched[props.inpName] && props.errors[props.inpName] ? true : false;
  if (isInvalid) {
    invalidStyle = {
      control: (provided, state) => ({
        ...provided,
        border: "1px solid red",
        "&:hover": { borderColor: "red" },
        boxShadow: "none",
      }),
    };
  }

  useEffect(() => {}, [menuIsOpen]);
  if (props.val !== undefined) {
    const options = [];
    props.val.forEach((v, i) => {
      options.push({ value: v["id"], label: v["value"] });
    });

    // console.log({
    //   options,
    //   val: props.values,
    //   inpName: props.inpName,
    // });

    return (
      <div>
        <Select
          name={props.inpName}
          onMenuOpen={() => setMenuIsOpen(!menuIsOpen)}
          onMenuClose={() => setMenuIsOpen(!menuIsOpen)}
          /* styles={{
            container: () => ({
              zIndex: menuIsOpen ? 999 : 1,
              top: menuIsOpen ? 1 : 0,
              position: menuIsOpen ? "relative" : "",
            }),
           
          }} */
          styles={invalidStyle}
          options={options}
          className={`basic-multi-select}`}
          classNamePrefix="select"
          onChange={sendDropdownValue}
          isClearable={true}        
          value={options.filter(
            (option) => option.value === props.values[props.inpName]
          )}
        />
      </div>
    );
  } else {
    return <Spinner></Spinner>;
  }
};

export default SingleDropDown;
