import { Form, Formik, useFormikContext } from "formik";
import React, { act, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form as bootstrapForm,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { ReactComponent as QuestionIcon } from "../Common/icons/Question.svg";
import basicInfoGreetings from "../Common/overlayImages/basicInfoGreetings.jpg";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import { AddSingleTags } from "./../Common/AddSingleTags";
import { AddDnDSingleTags } from "./../Common/AddDnDSingleTags";
import { CKEditorComponent } from "./../Common/CkEditor";
import CurrencyDropdown from "./../Common/CurrencyDropdown";
import { DragDrop } from "./../Common/DragDrop";
import { GridProvider } from "../Common/GridContext";
import Switch from "react-switch";
import SingleDropDown from "../Common/singleDropDown";

export const HotelInformation = ({
  cmsContent,
  hotelId,
  selectedLanguage,
  baseLangId,
  hotelDetails,
}) => {
  const { t, i18n } = useTranslation();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [logoFromApi, setLogoFromApi] = useState([]);
  const [logoUrl, setLogoUrl] = useState("add");
  const [pictureFromApi, setPictureFromApi] = useState([]);
  const [pictureUrl, setPictureUrl] = useState("add");
  const [newTags, setNewTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);

  const selectedTags = useSelector(
    (state) => state?.hotelDetails?.result?.tags
  );
  const availableCurrency = useSelector(
    (state) => state?.constants?.currencies
  );
  const pmsProducts = useSelector((state) => state?.constants?.pmsProducts);

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState(null);
  const [allowAccess, setAllowAccess] = useState(true);
  const [content, setContent] = useState(null);
  const [editorState, setEditorState] = useState();
  const [logoPath, setLogoPath] = useState(null);
  const [picturePath, setPicturePath] = useState(null);

  const formSchema = yup.object().shape({
    // greetingMessage: yup.string().max(+TEXT_EDITOR_CONFIG.HTML_MAX_CHAR_LIMIT).required(),
    // logo: yup.string().required(),
    // picture: yup.string().required(),
    // googleURL: yup
    //   .string()
    //   .matches(REGEX.URL_VALIDATION, "Enter correct url!")
    // .required("Please enter valid URL"),
    // tag: yup.string(),
  });

  // sending content for text editor
  const getContent = (cmsBlock) => {
    // ${cmsBlock?.body || ""}
    return `${cmsBlock?.body}`;
  };

  let formInitialValue = {
    greetingMessage: "",
    logo: hotelDetails?.logo,
    picture: hotelDetails?.picture,
    googleURL:
      hotelDetails?.google_earth_link === "null"
        ? ""
        : hotelDetails?.google_earth_link,
    tag: "",
    auto_billing: false,
  };

  useEffect(() => {
    dispatch(actions.getPmsProducts(hotelId));
  }, []);

  useEffect(() => {
    if (cmsContent) {
      const content = getContent(
        cmsContent.body
          ? cmsContent
          : hotelDetails?.cms_blocks[0]?.cms_blocks_ts[0]
      );
      setEditorState(content);
      setContent(content);
      // setting up the greeting msg
      formikRef.current?.setFieldValue("greetingMessage", content);
    }
  }, [cmsContent]);

  useEffect(() => {
    setLogoPath(hotelDetails?.logo);
    setPicturePath(hotelDetails?.picture);
    setSelectedCurrency(hotelDetails?.currency_id);
    setSelectedCurrencySymbol(hotelDetails?.currency_id);
    if (hotelDetails?.logo && hotelDetails?.logo.length) {
      setLogoUrl("update");
      setLogoFromApi([{ arrId: 1, preview: hotelDetails?.logo }]);
    }

    if (hotelDetails?.picture && hotelDetails?.picture.length) {
      setPictureUrl("update");
      setPictureFromApi([{ arrId: 2, preview: hotelDetails?.picture }]);
    }
    setAllowAccess(Utils.isAccessAllowedByRoute(PRIVATE_ROUTES.floor_create));
    formikRef.current?.setFieldValue("auto_billing", hotelDetails.auto_billing);
  }, [hotelDetails]);

  useEffect(() => {
    if (
      hotelDetails?.hotel_room_products?.length > 0 &&
      pmsProducts?.length > 0
    ) {
      const hotelRoomProducts = hotelDetails?.hotel_room_products.reduce(
        (acc, curr) => {
          acc[curr.value] = curr.product_id;
          return acc;
        },
        {}
      );
      // console.log(hotelRoomProducts);

      formikRef.current?.setFieldValue(
        "room_selection",
        hotelRoomProducts["Room Selection"]
      );
      formikRef.current?.setFieldValue(
        "room_upgrade",
        hotelRoomProducts["Room Upgrade"]
      );
    }
  }, [hotelDetails, pmsProducts]);

  const updateEditorState = (editorState, setFieldValue) => {
    setEditorState(editorState);
    setContent(editorState);
    // setFieldValue("greetingMessage", editorState);
  };

  const updateHotelGroup = (payload) => {
    delete payload.tag;
    const { greetingMessage, googleURL, languageId, currency_id } = payload;
    const logo =
      logoPath && logoPath.length ? logoPath[0].preview || null : null;
    const picture =
      picturePath && picturePath.length
        ? picturePath[0]?.preview || null
        : null;
    const refactoredPayload = {
      greeting_message: editorState,
      google_earth_link: googleURL,
      language_id: languageId,
      currency_id,
      tag_sort_data: JSON.stringify(tags),
    };

    if (newTags && newTags?.length) {
      refactoredPayload.tags = newTags.map((v) => v.name).toString();
    }

    if (deletedTags && deletedTags?.length) {
      refactoredPayload.deletedTags = deletedTags.toString();
    }

    if (logo !== hotelDetails?.logo) {
      refactoredPayload.logo = logoPath[0];
      refactoredPayload.is_logo_delete = true;
    }

    if (picture !== hotelDetails?.picture) {
      refactoredPayload.picture = picturePath[0];
      refactoredPayload.is_picture_delete = true;
    }

    if (payload.room_selection)
      refactoredPayload.room_selection = payload.room_selection;

    if (payload.room_upgrade)
      refactoredPayload.room_upgrade = payload.room_upgrade;

    if (payload.auto_billing) refactoredPayload.auto_billing = 1;

    if (!refactoredPayload.picture) {
      delete refactoredPayload.picture;
    } else {
      delete refactoredPayload.is_logo_delete;
    }

    if (!refactoredPayload.logo) {
      delete refactoredPayload.logo;
    } else {
      delete refactoredPayload.is_picture_delete;
    }

    // const plainText = Utils.getHTMLtoPlainText(
    //   refactoredPayload.greeting_message
    // );
    // validating plain text len
    // if (plainText && plainText.length < +TEXT_EDITOR_CONFIG.MAX_CHAR_LIMIT) {
    dispatch(actions.hotelDetailUpdate(refactoredPayload, hotelId));
    // }
  };

  // text editor content
  const textEditorContent = (errors, setFieldValue) => {
    return (
      <Row>
        <Col md={6} sm={12}>
          <CKEditorComponent
            editorState={editorState}
            allowAccess={allowAccess}
            newContent={content}
            showValidation={false}
            setEditorState={(updateContent) => {
              updateEditorState(updateContent, setFieldValue);
            }}
          />
        </Col>
        <Col md={6} sm={12}>
          <div className="sub-header-text">
            {t("Modules.Basic information.Available variables")}
          </div>
          <div className="mt-2 available-variables">
            {(hotelDetails?.variables).map((variable) => {
              return (
                <p>
                  {variable?.variable_name} - {variable?.description}
                </p>
              );
            })}
          </div>
        </Col>
      </Row>
    );
  };

  const logoAndPictureContent = (setFieldValue) => {
    return (
      <>
        <div>
          <div className="sub-header">
            {t("Modules.Basic information.Logo")}
          </div>
          <div className="sub-header-text">
            {t("Modules.Basic information.Logo - Description")}
          </div>
          <div className="mt-3 mb-3">
            <DragDrop
              maximumFile={1}
              multiUpload={false}
              acceptingImageTypes="image/png,image/jpeg"
              handleChange={setLogoPath}
              values={logoFromApi}
              path={logoUrl}
            ></DragDrop>
            <div>
              <div style={{ fontSize: "small" }}>
                {t("Common Translations.Picture Validation")}
              </div>
            </div>
          </div>
        </div>
        <hr className="border-line" />
        <div>
          <div className="sub-header">
            {t("Modules.Basic information.Picture")}
          </div>
          <div className="sub-header-text">
            {t("Modules.Basic information.Picture - Description")}
          </div>
          <div className="mt-3 mb-3">
            <DragDrop
              maximumFile={1}
              acceptingImageTypes="image/png,image/jpeg"
              multiUpload={false}
              handleChange={setPicturePath}
              values={pictureFromApi}
              path={pictureUrl}
            ></DragDrop>
            <div>
              <div style={{ fontSize: "small" }}>
                {t("Common Translations.Picture Validation")}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const updateCurrencySymbol = (selectedCurrencyId) => {
    const latestCurrencySymbol = availableCurrency?.symbol?.find(
      (availableSymbol) => {
        return +availableSymbol.id === +selectedCurrencyId;
      }
    );
    setSelectedCurrencySymbol(latestCurrencySymbol?.value);
  };

  const availableCurrencies = () => {
    const selectedCurrencyId = selectedCurrency
      ? selectedCurrency
      : availableCurrency
      ? availableCurrency?.list[52]?.id
      : [];

    setSelectedCurrency(selectedCurrencyId);
    updateCurrencySymbol(selectedCurrencyId);
    return (
      <>
        <Row>
          <h5>{t("Modules.Basic information.Currency")}</h5>
          <Col md={4} xs={4} sm={4}>
            <CurrencyDropdown
              val={availableCurrency?.list}
              selected={selectedCurrencyId}
              inpName={"currency"}
              allowAccess={allowAccess}
              updateDropdown={(updatedValue) => {
                setSelectedCurrency(updatedValue?.value);
                // updateCurrencySymbol(updatedValue.value);
              }}
              values={availableCurrency?.list}
            />
          </Col>
          <Col md={2} xs={3} sm={2}>
            <bootstrapForm>
              <bootstrapForm.Control
                type="text"
                name="currency-icon"
                value={selectedCurrencySymbol}
                disabled
              />
            </bootstrapForm>
          </Col>
        </Row>
      </>

      // <></>
    );
  };

  const isAutoBillingEnabled = () => {
    const allowedPMS = [2, 4, 6];
    return allowedPMS.includes(
      hotelDetails?.pms_hotel?.pms_integration?.pms_master_id
    );
  };

  const configurationForm = () => {
    return (
      hotelDetails && (
        <Formik
          innerRef={formikRef}
          initialValues={{
            ...formInitialValue,
          }}
          validationSchema={formSchema}
          onSubmit={(values) => {
            const payload = {
              ...values,
              greetingMessage: editorState,
              languageId: selectedLanguage,
              currency_id: +selectedCurrency,
            };
            updateHotelGroup(payload);
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            submitForm,
            handleBlur,
            setFieldValue,
          }) => {
            return (
              <Form>
                <p>
                  <span className="sub-header">
                    {t("Modules.Basic information.Greetings Message")}
                  </span>
                  <span className="p-3">
                    <Link to="/hotel-translation">
                      <strong>
                        {t("Modules.Basic information.Manage translations")}
                      </strong>
                    </Link>
                  </span>
                </p>
                <p className="sub-header-text">
                  {t(
                    "Modules.Basic information.Greetings message - Description"
                  )}
                  <span className="m-2">
                    <OverlayTrigger
                      key="right"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          <Row>
                            <Col md={3}>
                              {t(
                                "Modules.Basic information.Greetings message - Question mark"
                              )}
                            </Col>
                            <Col>
                              <img
                                src={basicInfoGreetings}
                                className={"tooltip-image"}
                                alt={"greeting-overview"}
                              ></img>
                            </Col>
                          </Row>
                        </Tooltip>
                      }
                    >
                      <QuestionIcon className="pointer" />
                    </OverlayTrigger>
                  </span>
                </p>
                {/* text editor */}
                {textEditorContent(errors, setFieldValue)}
                <hr className="border-line" />
                {availableCurrencies()}
                <hr className="border-line" />
                {/* logo and picture */}
                {logoAndPictureContent(setFieldValue)}
                <hr className="border-line" />
                <div>
                  <div className="sub-header">
                    {t("Modules.Basic information.Google Maps URL")}
                  </div>
                  <div className="sub-header-text">
                    {t(
                      "Modules.Basic information.Google Maps URL - Description"
                    )}
                  </div>
                  <div className="mt-3 mb-3">
                    <bootstrapForm.Control
                      type="text"
                      //placeholder="Google url"
                      disabled={!allowAccess}
                      name="googleURL"
                      value={values?.googleURL?.trimStart()}
                      onChange={handleChange}
                      isInvalid={errors?.googleURL}
                    />
                  </div>
                </div>
                <hr className="border-line" />
                <div>
                  <div className="sub-header">
                    {t("Modules.Basic information.Room features")}
                  </div>
                  <div className="sub-header-text">
                    {t(
                      "Modules.Basic information.Define room features - Description"
                    )}
                  </div>
                  <div className="mt-3 mb-3">
                    {/* <bootstrapForm.Label>
                      {t(
                        "Modules.Basic information.Type your tag"
                      )}
                    </bootstrapForm.Label> */}
                    <Row>
                      <DndProvider backend={HTML5Backend}>
                        <GridProvider>
                          <AddDnDSingleTags
                            allowAccess={allowAccess}
                            updateTags={{ tags, setTags }}
                            removeTags={{ deletedTags, setDeletedTags }}
                            setNewTags={{ newTags, setNewTags }}
                            existingTags={selectedTags}
                            selectedLanguage={selectedLanguage}
                          />
                        </GridProvider>
                      </DndProvider>
                    </Row>
                    <hr className="border-line" />
                    {isAutoBillingEnabled() ? (
                      <Row
                        className="mt-3 sub-header-text"
                        style={{ fontSize: "13px" }}
                      >
                        <label style={{ fontSize: "13px" }}>
                          {t("Modules.Basic information.Auto Billing", {
                            defaultValue: "Auto Billing - Room",
                          })}
                        </label>
                        <div>
                          <span className="switch-lable">
                            {t("Modules.Coupons.Switch - Off")}
                          </span>
                          <Switch
                            onChange={(value) => {
                              setFieldValue("auto_billing", value);
                            }}
                            checked={values?.auto_billing ? true : false}
                            name="auto_billing"
                          />
                          <span className="switch-lable">
                            {t("Modules.Coupons.Switch - On")}
                          </span>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                    {isAutoBillingEnabled() && values?.auto_billing ? (
                      <>
                        <Row className="mt-3">
                          <Col md={2}>
                            <bootstrapForm.Group>
                              <bootstrapForm.Label
                                className={"sub-header-text"}
                              >
                                {t("Modules.Basic information.Room Selection", {
                                  defaultValue: "Room Selection Product",
                                })}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </bootstrapForm.Label>
                              <SingleDropDown
                                val={pmsProducts}
                                inpName={"room_selection"}
                                updateDropdown={setFieldValue}
                                touched={touched}
                                errors={errors}
                                values={values}
                              ></SingleDropDown>
                            </bootstrapForm.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={2}>
                            <bootstrapForm.Group>
                              <bootstrapForm.Label
                                className={"sub-header-text"}
                              >
                                {t("Modules.Basic information.Room Upgrade", {
                                  defaultValue: "Room Upgrade Product",
                                })}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </bootstrapForm.Label>
                              <SingleDropDown
                                val={pmsProducts}
                                inpName={"room_upgrade"}
                                updateDropdown={setFieldValue}
                                touched={touched}
                                errors={errors}
                                values={values}
                              ></SingleDropDown>
                            </bootstrapForm.Group>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                    <hr className="border-line" />
                    {/* on submit event */}
                    <Row>
                      <Col md={2} xs={3} sm={3}>
                        {allowAccess ? (
                          <Button
                            // allowing to submit if lang is primary/base lang
                            disabled={selectedLanguage !== baseLangId}
                            onClick={() => {
                              submitForm();
                            }}
                            variant="primary"
                          >
                            {t("Common Translations.Save")}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col md={2} xs={3} sm={3}>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            history.push("/hotel-management");
                          }}
                        >
                          {t("Common Translations.Cancel")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )
    );
  };

  return <>{configurationForm()}</>;
};
