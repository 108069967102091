import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TABS_TRANSLATION } from "../../helper/constants";
import { StorageService } from "./../../services/storage.service";
import actions from "./../../store/actions/actions";
import { Utils } from "./../../utils/utils";
import Spinner from "./../Common/Spinner";
import ModalComponent from "./../Common/ModalComponent";
import { CategoryTranslation } from "./Category";
import { DealsTranslation } from "./Deals";
import { GreetingMessagesTranslation } from "./greetingMessage";
import { OffersTranslation } from "./Offers";
import { RoomsTranslation } from "./Rooms";
import { SeasonTranslation } from "./Season";
import { TagsTranslation } from "./Tags";
import { FloorTranslation } from "./Floor";

const UNSAVED_ACTIONS = {
  SAVE: "SAVE",
  DISCARD: "DISCARD",
  CANCEL: "CANCEL",
};

export const TranslationTabs = () => {
  const tabsKeyValue = TABS_TRANSLATION;
  const defaultLimit = 10;
  const defaultPage = 1;
  const storageService = new StorageService();
  const hotelId = +storageService.getItem("hotelId");
  const [languageIds, setLanguageIds] = useState([]);
  const [languageNames, setLanguageNames] = useState([]);
  const availableLanguagesFromSelector = useSelector(
    (state) => state.constants.languages
  );
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [tempPayload, setTempPayload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const availableFilteredLanguages = Utils.setupLanguageRes(
    availableLanguagesFromSelector
  );

  // TODO: make it to default 0'th index as selected
  const [activeTab, setActiveTab] = useState(tabsKeyValue[0].id);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setSearchHotelRoomList(""));
    dispatch(actions.setSearchFloorList(""));
    dispatch(actions.setSearchSeason(""));
    dispatch(actions.setSearchDealList(""));
    dispatch(actions.setSearchOfferList(""));
    dispatch(actions.setSearchUserList(""));
  }, []);

  const isLoading = useSelector((state) => state?.network?.isLoading);
  const tabsInfo = useSelector((state) => state?.translationTab?.result);

  useEffect(() => {
    if (availableFilteredLanguages && availableFilteredLanguages.length) {
      // sorting language by ids
      availableFilteredLanguages.sort((indexV1, indexV2) => {
        return indexV1?.language_id - indexV2?.language_id;
      });
      const languageIds = availableFilteredLanguages.map((languages) => {
        return languages?.language_id;
      });
      const languageNames = availableFilteredLanguages.map((languages) => {
        return { header: languages?.language?.code };
      });
      setLanguageIds(languageIds);
      setLanguageNames(languageNames);
    }
  }, [availableLanguagesFromSelector]);

  useEffect(() => {
    const payload = {
      hotelId: +hotelId,
      filter: tabsKeyValue[activeTab - 1].key,
    };
    dispatch(actions.getTranslationTabInfo({ ...payload }));
  }, []);

  const updateActiveTab = (tabInfo) => {
    setActiveTab(tabInfo.id);
    const key = tabInfo.key;
    // for id 2 it's tab and it's already integrated
    if (key && tabInfo.id !== 2) {
      // for now deals and offer is on same key
      // if (tabInfo.key.toLowerCase() === "deals") {
      //   // key = "offer";
      // }
      const payload = {
        hotelId: +hotelId,
        filter: key,
        page: defaultPage,
        limit: defaultLimit,
      };
      dispatch(actions.getTranslationTabInfo({ ...payload }));
    }
  };

  const fetchExtra = (payloadInfo) => {
    const payload = {
      hotelId: +hotelId,
      filter: getKeyName(activeTab),
      page: payloadInfo.page,
      limit: defaultLimit,
    };
    dispatch(actions.getTranslationTabInfo({ ...payload }));
  };

  const getDealsAndOffer = (data, type) => {
    const filteredResult = data?.filter((offerInfo) => {
      return offerInfo.type === type;
    });
    return filteredResult;
  };

  const onPageChange = (payload) => {
    if (hasDataChanged) {
      setShowModal(true);
      setTempPayload(payload);
      return;
    }
    fetchExtra(payload);
  };

  const onModalButtonsClick = (action) => {
    switch (action) {
      case UNSAVED_ACTIONS.SAVE: {
        dispatch(actions.setUnsavedChangesFlag(true));
        setTimeout(() => {
          fetchExtra(tempPayload);
          setShowModal(false);
          setHasDataChanged(false);
        }, 1500);
        break;
      }
      case UNSAVED_ACTIONS.DISCARD: {
        setShowModal(false);
        fetchExtra(tempPayload);
        setHasDataChanged(false);
        break;
      }
      case UNSAVED_ACTIONS.CANCEL: {
        setShowModal(false);
        break;
      }
      default: {
        setShowModal(false);
        setHasDataChanged(false);
      }
    }
  };

  const showTabContent = () => {
    switch (activeTab) {
      case 1: {
        return (
          <>
            <GreetingMessagesTranslation
              data={tabsInfo?.greeting}
              languageInfo={availableFilteredLanguages}
              languageNames={languageNames}
              hotelId={hotelId}
              filter={getKeyName(activeTab)}
              setHasDataChanged={setHasDataChanged}
            />
          </>
        );
      }
      case 2: {
        return (
          <>
            <TagsTranslation />
          </>
        );
      }
      case 3: {
        return (
          <>
            <FloorTranslation
              data={tabsInfo?.floor}
              totalCount={tabsInfo?.totalCount ? tabsInfo?.totalCount : 1}
              pageNo={tabsInfo?.page ? +tabsInfo?.page : 1}
              onPageChange={onPageChange}
              languageIds={languageIds}
              languageNames={languageNames}
              hotelId={hotelId}
              filter={getKeyName(activeTab)}
              setHasDataChanged={setHasDataChanged}
            />
          </>
        );
      }
      case 4: {
        return (
          <>
            <CategoryTranslation
              data={tabsInfo?.category}
              totalCount={tabsInfo?.totalCount ? tabsInfo?.totalCount : 1}
              pageNo={tabsInfo?.page ? +tabsInfo?.page : 1}
              onPageChange={onPageChange}
              languageIds={languageIds}
              languageNames={languageNames}
              hotelId={hotelId}
              filter={getKeyName(activeTab)}
              setHasDataChanged={setHasDataChanged}
            />
          </>
        );
      }
      case 5: {
        return (
          <>
            <SeasonTranslation
              data={tabsInfo?.season}
              totalCount={tabsInfo?.totalCount ? tabsInfo?.totalCount : 1}
              pageNo={tabsInfo?.page ? +tabsInfo?.page : 1}
              onPageChange={onPageChange}
              languageIds={languageIds}
              languageNames={languageNames}
              hotelId={hotelId}
              filter={getKeyName(activeTab)}
              setHasDataChanged={setHasDataChanged}
            />
          </>
        );
      }
      case 6: {
        return (
          <>
            <RoomsTranslation
              data={tabsInfo?.room}
              totalCount={tabsInfo?.totalCount ? tabsInfo?.totalCount : 1}
              pageNo={tabsInfo?.page ? +tabsInfo?.page : 1}
              onPageChange={onPageChange}
              languageIds={languageIds}
              languageNames={languageNames}
              hotelId={hotelId}
              filter={getKeyName(activeTab)}
              setHasDataChanged={setHasDataChanged}
              hasDataChanged={hasDataChanged}
            />
          </>
        );
      }
      case 7: {
        return (
          <>
            <DealsTranslation
              data={getDealsAndOffer(tabsInfo?.offer, "service")}
              totalCount={tabsInfo?.totalCount ? tabsInfo?.totalCount : 1}
              pageNo={tabsInfo?.page ? +tabsInfo?.page : 1}
              onPageChange={onPageChange}
              languageIds={languageIds}
              languageNames={languageNames}
              hotelId={hotelId}
              filter={"deals"}
              setHasDataChanged={setHasDataChanged}
            />
          </>
        );
      }
      case 8: {
        return (
          <>
            <OffersTranslation
              data={getDealsAndOffer(tabsInfo?.offer, "offer")}
              totalCount={tabsInfo?.totalCount ? tabsInfo?.totalCount : 1}
              pageNo={tabsInfo?.page ? +tabsInfo?.page : 1}
              onPageChange={onPageChange}
              languageIds={languageIds}
              languageNames={languageNames}
              hotelId={hotelId}
              filter={getKeyName(activeTab)}
              setHasDataChanged={setHasDataChanged}
            />
          </>
        );
      }
      default:
        return <> </>;
    }
  };

  const getKeyName = (index) => {
    const keyInfo = tabsKeyValue.find((keys) => {
      return index === keys.id;
    });
    return keyInfo.key;
  };

  return (
    <React.Fragment>
      <div className="hotel-nav" variant="pills">
        {tabsKeyValue?.map((tabInfo, key) => (
          <Button
            onClick={() => {
              updateActiveTab(tabInfo);
            }}
            variant="secondary"
            key={`tab-info-${key}`}
            className={`hotel-nav-item m-2 ${
              tabInfo?.key === getKeyName(activeTab) ? "hotel-tab" : ""
            }`}
          >
            <span key={`tab-label-${key}`}>{tabInfo?.value}</span>
          </Button>
        ))}
      </div>
      {/* not showing loader for tab 2 because it's already showing in child component*/}
      {activeTab !== 2 ? (
        <>
          {!isLoading ? (
            <>
              <div className="mt-3">{showTabContent()}</div>
            </>
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <>
          <div className="mt-3">{showTabContent()}</div>
        </>
      )}
      <ModalComponent
        show={showModal}
        className="unsaved-changes-popup"
        body={
          <Row>
            <Col>
              Hey there! It looks like you're in the middle of writting
              something and you haven't saved all your content.
              <p>Save before you go?</p>
              <Row className="btn-grp">
                <Button
                  onClick={() => onModalButtonsClick(UNSAVED_ACTIONS.SAVE)}
                >
                  Save
                </Button>

                <Button
                  onClick={() => onModalButtonsClick(UNSAVED_ACTIONS.DISCARD)}
                >
                  Discard Changes
                </Button>

                <Button
                  onClick={() => onModalButtonsClick(UNSAVED_ACTIONS.CANCEL)}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          </Row>
        }
        onHide={() => {
          setShowModal(false);
        }}
      />
    </React.Fragment>
  );
};
